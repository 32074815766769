/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import { useTranslation } from "react-i18next";

const EventListFilterPopup = ({
  tagList,
  eventTypeList,
  setfilterEventCity,
  setfilterEventCountry,
  setFilterDateRange,
  setFilterEventTags,
  setFilterEventType,
  setFilterEventTitle,
  setfilterCompanies,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [typeValues, settypeValues] = useState([]);
  const [tagValues, settagValues] = useState([]);
  const [title, settitle] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  const [companyValue, setCompanyValue] = useState(null);
  const [companyIds, setCompanyIds] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    setCompanyValue(val);
    setCompanyIds(val.map((item) => item.value));
  };

  //function for select event type
  const eventTypeSelectionHandler = (data) => {
    let prevTypeValues = typeValues;

    if (prevTypeValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "eventtypefilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTypeValues.indexOf(data.value);
      prevTypeValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "eventtypefilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTypeValues.push(data.value);
    }
    settypeValues(prevTypeValues);
  };

  //function for select event tags
  const eventTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "eventtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "eventtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    setFilterEventTitle(title);
    setfilterEventCity(city);
    setfilterEventCountry(country);
    setFilterEventType(typeValues);
    setFilterEventTags(tagValues);
    setfilterCompanies(companyIds);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasEventFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    settitle("");
    setfromDate("");
    settoDate("");
    settypeValues([]);
    settagValues([]);
    setcity("");
    setcountry("");
    setcompanyInput("");
    setCompanyList([]);
    setCompanyValue(null);
    setCompanyIds([]);
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasEventFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ---- date accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Dates")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group d-flex gap-2">
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("From date")}
                      value={fromDate}
                      onChange={(e) => setfromDate(e.target.value)}
                    />
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("To date")}
                      value={toDate}
                      onChange={(e) => settoDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ---- date accordion end ----- */}

            {/* ----- title accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseZero"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseZero"
                >
                  {t("Title")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseZero"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Event title")}
                      value={title}
                      onChange={(e) => settitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ----- title accordion end ----- */}

            {/* ----- type accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Type/Sector")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {eventTypeList.map((eventType, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => eventTypeSelectionHandler(eventType)}
                            id={"eventtypefilter-" + eventType.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{eventType.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ----- type accordion end ----- */}

            {/* company */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseComp"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseComp"
                >
                  {t("Company")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseComp"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <Select
                      isMulti
                      placeholder={t("Type Company name")}
                      options={companyOptionsToShow}
                      value={companyValue}
                      onChange={(val) => {
                        companySelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        setcompanyInput(val);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ----- city accordion start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("City")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("City")}
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ----- city accordion end ------ */}

            {/* ----- country accordion start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Country")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Country")}
                      value={country}
                      onChange={(e) => setcountry(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ----- country accordion end ------ */}

            {/* ----- tag accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => eventTagSelectionHandler(tagData)}
                            id={"eventtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ----- tag accordion end ----- */}
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Go")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EventListFilterPopup;
