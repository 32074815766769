/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import * as url from "helper/UrlHelper";
import { postData, getData, putData } from "utils/Gateway";

import NoteSection from "components/Common/NoteComponent/NoteSection";

import { useTranslation } from "react-i18next";

const SaveLeadPopup = ({
    selectedTagId, 
    setSelectedTagId,
    selectedComponentId,
    setSelectedComponentId,
    setReloadData

}) => {

    const params = useParams();
    const history = useHistory();
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ecosystemSlug = localStorage.getItem("ecosystemslug");

    const { t } = useTranslation(); //for translation

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [website, setWebsite] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [companyName, setCompanyName] = useState("");
    
    const [followersOptions, setFollowersOptions] = useState([]);
    const [followersValue, setFollowersValue] = useState([]);
    const [selectedFollowers, setselectedFollowers] = useState([]);
    const [followersInput, setFollowersInput] = useState("");

    const [moderatorId, setModeratorId] = useState(userInfo._id.toString());


    const [isSending, setIsSending] = useState(false);

    const [noteList, setNoteList] = useState([]);
    const [invoiceNote, setInvoiceNote] = useState("");

    const [noteAccessUserList, setNoteAccessUserList] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState(null);

    const [validation, setValidation] = useState({
        firstNameWarning: false,
        emailAddressWarning: false,
        notValidMail: false,
    });

    
    //function for get followers list
    const getFollowersList = async () => {
        try {
            let requestUrl =
            url.API_BASE_URL +
            url.API_GET_USERLIST_OPTIONS +
            `?token=${token}&userstringinput=${followersInput}`;

            const response = await getData(requestUrl);

            if (response.status) {
                if (response.data.length > 0) {
                    setFollowersOptions(response.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    //select followers
    const followersSelectionHandler = (val) => {

        //console.log('followersSelectionHandler', val);

        setFollowersValue(val);
        setselectedFollowers(val.map((item) => item.value));

    };

    //function for get followers input
    const followersInputHandler = (val) => {
        setFollowersInput(val);
    }

    const validationHandler = () => {
        let isValid = true;

        if (firstName === "") {
        setValidation((prevState) => ({ ...prevState, firstNameWarning: true }));
        isValid = false;
        }

        if (emailAddress === "") {
        setValidation((prevState) => ({
            ...prevState,
            emailAddressWarning: true,
        }));
        isValid = false;
        }

        if (emailAddress !== "") {
            const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!emailAddress.match(mailformat)) {
                setValidation((prevState) => ({
                ...prevState,
                notValidMail: true,
                }));
                isValid = false;
            }
        }

        // if valid then unset the warning
        if (isValid) {
            setValidation((prevState) => ({
                ...prevState,
                firstNameWarning: false,
                emailAddressWarning: false,
                notValidMail: false,
            }));
        }

        return isValid;
    };

    const saveUpdateLeadHandler = async () => {
        if (validationHandler()) {
            try {
                setIsSending(true);

                let leadData = {
                    name: firstName,
                    surname: lastName,
                    email: emailAddress,
                    phone: phoneNumber,
                    company: companyName,
                    dob: dateOfBirth,
                    website,
                    followers: selectedFollowers,
                    source: `${ecosystemSlug} Backend`,
                };

                console.log('leadData', leadData);

                let requestUrl = url.API_BASE_URL;
                let response = {};

                if (selectedComponentId) {
                    requestUrl =
                        requestUrl +
                        url.API_UPDATE_LEAD +
                        `/${selectedComponentId}` +
                        `?token=${token}`;
                    
                    console.log('udpate requestUrl', requestUrl);

                    response = await putData(requestUrl, leadData);

                    console.log('update response', response);

                } else {

                    // add tag too
                    //console.log('setSelectedTagId', setSelectedTagId);

                    leadData.defaultTag = selectedTagId; 
                    
                    requestUrl = requestUrl + url.API_CREATE_LEAD + `?token=${token}`;

                    console.log('create requestUrl', requestUrl);

                    response = await postData(requestUrl, leadData);

                    console.log('create response', response);
                }

                setIsSending(false);

                if (response.status) {

                    let offCanvasPopup = document.querySelector("#saveLeadPopup");
                    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
                    offCanvas.hide();

                    setSelectedComponentId(null);

                    setReloadData(true);
                    
                }

            } catch (error) {
                console.error(error.message);
            }
        }
    };

    const closeHandler = () => {
        setSelectedComponentId(null);
        setSelectedTagId(null);

        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setPhoneNumber("");
        setWebsite("");
        setDateOfBirth("");
        setCompanyName("");
        setFollowersValue(null);
        setModeratorId(userInfo._id.toString());

        setValidation({
        firstNameWarning: false,
        emailAddressWarning: false,
        notValidMail: false,
        });
    }

    useEffect(() => {
        if (followersInput.length > 0) {
        getFollowersList();
        }
    }, [followersInput]);

    const getLeadDetails = async () => {

        console.log('selectedComponentId', selectedComponentId);

        try {
            let requestUrl = url.API_BASE_URL + url.API_GET_LEAD_DETAILS + `/${selectedComponentId}?token=${token}`;
            
            const response = await getData(requestUrl);

            console.log('response', response);
            
            if (response.status) {
                setFirstName(response.data.name);
                setLastName(response.data.surname);
                setEmailAddress(response.data.email);
                setPhoneNumber(response.data.phone);
                setWebsite(response.data.website);
                setDateOfBirth(response.data.dob);
                setCompanyName(response.data.company);

                //console.log('followersarr', response.data.followersarr);

                setFollowersValue(response.data.followersarr);
     
                if(response.data.moderator && response.data.moderator._id){
                    setModeratorId(response.data.moderator._id.toString());
                }
                
                // Show the offcanvas after setting the state
                let offCanvasPopup = document.querySelector("#saveLeadPopup");
                let offCanvas = new bootstrap.Offcanvas(offCanvasPopup);
                offCanvas.show();
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    // get lead details from component id
    useEffect(() => {

        console.log('userInfo._id.toString()', userInfo._id.toString());
        console.log('moderatorId', moderatorId);

        if (selectedComponentId) {
            getLeadDetails();
        }
    }, [selectedComponentId]);



  return (
    <div className="offcanvas invoice_w offcanvas-end bg-white border-0" 
        tabIndex="-1" 
        id="saveLeadPopup"
        aria-labelledby="saveLeadPopup"
        >

        <div className="offcanvas-header p-4 pb-0">
            <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
            {selectedComponentId || (userInfo._id.toString() === moderatorId )? 'Save' : 'View' }  Lead
            </h3>
            <button
            type="button"
            className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={closeHandler}
            ></button>
        </div>

        <div className="offcanvas-body p-4">
            <div className="row">
                <div className="col-lg-8">
                    <div className="p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                        <form onSubmit={(e) => e.preventDefault()}>

                            <div className="fields-container flex-fill">
                                <div className="form-group mb-4 d-flex gap-4">
                                    <div className="flex-fill">
                                        <label htmlFor="firstName" className="form-label">
                                        First Name
                                        </label>
                                        <input
                                        type="text"
                                        className="form-control fs-sm shadow-none"
                                        id="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        />

                                        {validation.firstNameWarning && (
                                        <small className="text-danger">
                                            {t("First Name is required")}
                                        </small>
                                        )}
                                    </div>
                                    <div className="flex-fill">
                                        <label htmlFor="lastName" className="form-label">
                                        Last Name
                                        </label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="emailAddress" className="form-label">
                                    Email
                                    </label>
                                    <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    />
                                    {validation.emailAddressWarning && (
                                    <small className="text-danger">
                                        {t("Email is required")}
                                    </small>
                                    )}
                                </div>

                                <div className="form-group d-flex mb-4 gap-4">
                                    <div className="flex-fill">
                                        <label htmlFor="phoneNumber" className="form-label">
                                        Phone
                                        </label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex-fill">
                                        <label htmlFor="dateOfBirth" className="form-label">
                                        Date of Birth
                                        </label>
                                        <input
                                        type="date"
                                        className="form-control"
                                        id="dateOfBirth"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-4 d-flex gap-4">
                                    <div className="flex-fill">
                                        <label htmlFor="website" className="form-label">
                                        Website
                                        </label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="website"
                                        value={website}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        />
                                    </div>

                                    <div className="flex-fill">
                                        <label htmlFor="company" className="form-label">
                                        Company
                                        </label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="company"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </div>
                                    
                                </div>
                                
                                { (userInfo._id.toString() === moderatorId )?(
                                <div className="form-group mb-4">
                                    <label
                                    htmlFor="followers"
                                    className="d-block fs-sm fw-semibold mb-2"
                                    >
                                    {t("Followers")}
                                    </label>
                                    <Select
                                    isMulti
                                    placeholder={t("Type user name or email")}
                                    options={followersOptions}
                                    value={followersValue}
                                    onChange={(val) => followersSelectionHandler(val)}
                                    onInputChange={(val) => {
                                        followersInputHandler(val);
                                    }}
                                    />
                                </div>
                                ):''}

                            </div>


                            { (userInfo._id.toString() === moderatorId )?(
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={saveUpdateLeadHandler}
                                disabled={isSending}
                            >
                                Save
                                {isSending && (
                                <div
                                    className="mx-2 spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                )}
                            </button>
                            ):''}


                            {(validation.firstNameWarning || validation.emailAddressWarning || validation.notValidMail) && (
                                <small className="alert alert-danger ms-2">
                                    {t('Please correct the highlighted fields before saving.')}
                                </small>
                            )}


                        </form>

                    </div>
                </div>
                <div className="col-lg-4">
                    {selectedComponentId && (
                        <NoteSection
                            noteAccessUserList={noteAccessUserList}
                            moduleName = 'LEAD'
                            leadId={selectedComponentId}
                        />
                    
                    )}
                    
                  
                </div>
            </div>        
        </div>
        
    </div>
  );
};

export default SaveLeadPopup;