/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const SurveyInviteListFilter = ({
  setfilterStartDate,
  setfilterEndDate,
  setfilterName,
  setfilterEmail,
  setfilterType,
  setfilterTags,
}) => {
  const { t } = useTranslation(); //for translation

  //get token form localstorage
  const token = localStorage.getItem("token");

  const [tagList, setTagList] = useState([]);

  const inviteTypeOptions = [
    {
      label: "Contact",
      value: "Contact",
    },
    {
      label: "Lead",
      value: "Lead",
    },
  ];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tags, setTags] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [inviteTypeValue, setinviteTypeValue] = useState(null);
  const [inviteType, setInviteType] = useState("");

  const [errorMessage, seterrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select invite type
  const inviteTypeSelectionHandler = (val) => {
    if (val) {
      setinviteTypeValue(val);
      setInviteType(val.value);
    } else {
      setinviteTypeValue(null);
      setInviteType("");
    }
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = tags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");
      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      if (fullName !== "") {
        setfilterName(fullName);
      } else {
        setfilterName(fullName);
      }

      if (email !== "") {
        setfilterEmail(email);
      } else {
        setfilterEmail("");
      }

      if (inviteType !== "") {
        setfilterType(inviteType);
      } else {
        setfilterType("");
      }

      if (tags.length > 0) {
        setfilterTags(tags);
      } else {
        setfilterTags([]);
      }

      resetHandler();

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasInviteFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //function for reset all states
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    setTags([]);
    setFullName("");
    setEmail("");
    setinviteTypeValue(null);
    setInviteType("");
    removeActiveClass();
    resetSelectTags();
    seterrorMessage("");
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasInviteFilter"
      aria-labelledby="offcanvasInviteFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start date range Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>

            {/* <Select
              placeholder={t("Filter date by")}
              isClearable
              options={FilterDateOptions}
              value={subscribeMailValue}
              onChange={(val) => {
                subscribeSelectionHandler(val);
              }}
            /> */}

            <div className="d-flex gap-2 ">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start name Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Name")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Name")}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          {/* <!-- ========== End name Section ========== --> */}

          {/* <!-- ========== Start email Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Email")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <!-- ========== End email Section ========== --> */}

          {/* <!-- ========== Start type Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Invite Type")}</h3>{" "}
            </label>
            <Select
              isClearable
              placeholder={t("Select Invite Type")}
              options={inviteTypeOptions}
              value={inviteTypeValue}
              onChange={(val) => inviteTypeSelectionHandler(val)}
            />
          </div>
          {/* <!-- ========== End type Section ========== --> */}

          {/* <!-- ========== Start tag accordion Section ========== --> */}
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start label/tag accordion Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapselabels"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapselabels"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapselabels"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tag)}
                            id={"tagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End label/tag accordion Section ========== --> */}
          </div>

          {/* <!-- ========== End tag accordion Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default SurveyInviteListFilter;
