import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const ColumnItem = (props) => {
  
  const { id, item } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 10,
    margin: 5,
    background: "#fff",
    border: "1px solid #ccc"
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div>ID: {item.id}</div>
      <div>Label: {item.label}</div>
      <div>Value: {item.value}</div>
    </div>
  );
};

export default ColumnItem;