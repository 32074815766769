/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const LogFilterPopup = ({
  visibleColoumns,
  setVisibleColoumns,
  columnOrder,
  setColumnOrder,
  moduleName,
  tagList,
  taskList,
  // projectList,
  setFilterTitle,
  setfilterStartDate,
  setfilterEndDate,
  setFilterTags,
  setfilterTaskIds,
  setfilterSectionIds,
  setfilterUserIds,
  setfilterProjectIds,
  isFilterReset,
  setIsFilterReset,
  setIsNoDefaultFilter,
}) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [projectInput, setprojectInput] = useState("");
  const [projectList, setProjectList] = useState([]);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [title, settitle] = useState("");

  const [logOwnerInput, setlogOwnerInput] = useState("");
  const [logOwnerOptions, setlogOwnerOptions] = useState([]);

  const [logOwnerValue, setlogOwnerValue] = useState(null);
  const [logOwnerIds, setlogOwnerIds] = useState([]);

  const [taskValue, settaskValue] = useState(null);
  const [taskIds, settaskIds] = useState([]);

  const [sectionValue, setsectionValue] = useState(null);
  const [sectionIds, setsectionIds] = useState([]);

  const [userValue, setuserValue] = useState(null);
  const [userIds, setuserIds] = useState([]);

  const [projectValue, setprojectValue] = useState(null);
  const [projectIds, setprojectIds] = useState([]);

  const [tagValues, settagValues] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign columns
      if (jsonFilterData.visibleColoumns) {
        setVisibleColoumns(jsonFilterData.visibleColoumns);
      }

      //assign column order
      if (jsonFilterData.columnOrder && jsonFilterData.columnOrder.length > 0) {
        setColumnOrder(jsonFilterData.columnOrder);
      }

      if (jsonFilterData.fromDate) {
        setfromDate(jsonFilterData.fromDate);
      } else {
        setfromDate("");
      }

      if (jsonFilterData.toDate) {
        settoDate(jsonFilterData.toDate);
      } else {
        settoDate("");
      }

      if (jsonFilterData.logOwnerValue) {
        logOwnerSelectionHandler(jsonFilterData.logOwnerValue);
      } else {
        setlogOwnerValue(null);
        setlogOwnerIds([]);
      }

      if (jsonFilterData.projectValue) {
        projectSelectionHandler(jsonFilterData.projectValue);
      } else {
        setprojectValue(null);
        setprojectIds([]);
      }

      if (jsonFilterData.tagValues && jsonFilterData.tagValues.length > 0) {
        // Filtering the skills options based on values present in the json array
        const filteredTags = tagList.filter((item) =>
          jsonFilterData.tagValues.includes(item.value)
        );

        //run loop
        filteredTags.forEach((item) => {
          logTagSelectionHandler(item);
        });
      } else {
        settagValues([]);
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //search input
  const projectInputHandler = (val) => {
    setprojectInput(val);
  };

  //function for get project list
  const getProjectList = async () => {
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_PROJECTLIST_BY_SEARCH}?token=${token}&searchtitle=${projectInput}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setProjectList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user details
  const getLogOwnerList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${logOwnerInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setlogOwnerOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get input
  const logOwnerInputHandler = (val) => {
    setlogOwnerInput(val);
  };

  //function Selection
  const logOwnerSelectionHandler = (val) => {
    setlogOwnerValue(val);
    setlogOwnerIds(val.map((item) => item.value));
  };

  //function for select event tags
  const logTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const projectSelectionHandler = (val) => {
    setprojectValue(val);
    setprojectIds(val.map((item) => item.value));
  };

  //   const sectionSelectionHandler = (val) => {
  //     setsectionValue(val);
  //     setsectionIds(val.map((item) => item.value));
  //   };

  //   const userSelectionHandler = (val) => {
  //     setuserValue(val);
  //     setuserIds(val.map((item) => item.value));
  //   };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate !== "") {
      setfilterStartDate(fromDate);
    } else {
      setfilterStartDate("");
    }

    if (toDate !== "") {
      setfilterEndDate(toDate);
    } else {
      setfilterEndDate("");
    }

    //     setFilterTitle(title);
    setFilterTags(tagValues);
    //     setfilterTaskIds(taskIds);
    //     setfilterSectionIds(sectionIds);
    setfilterUserIds(logOwnerIds);
    setfilterProjectIds(projectIds);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasLogFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      //set filter data for saving
      let filterData = {
        name: filterName,
        parameters: JSON.stringify({
          visibleColoumns: visibleColoumns ?? {},
          columnOrder: columnOrder ?? [],

          fromDate,
          toDate,
          logOwnerValue,
          logOwnerIds,
          projectValue,
          projectIds,
          tagValues,
        }),
        moduleslug: moduleName,
        isdefault: isDefaultFilter,
      };

      console.log(`filterData ==========>>>`, filterData);

      try {
        let requestUrl = url.API_BASE_URL;

        //check condition
        if (filterId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_FILTER +
            `/${filterId}` +
            `?token=${token}`;
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
        }

        const response = filterId
          ? await putData(requestUrl, filterData)
          : await postData(requestUrl, filterData);

        setFilterIsSaving(false);

        console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          searchHandler();
          if (filterId) {
            getAllFilters(false);
          } else {
            getAllFilters(true);
          }
        } else {
          setFilterSavingErrorMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(true);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            console.log(jsonFilterData);

            //assign visible columns
            if (jsonFilterData.visibleColoumns) {
              setVisibleColoumns(jsonFilterData.visibleColoumns);
            }

            //assign column order
            if (
              jsonFilterData.columnOrder &&
              jsonFilterData.columnOrder.length > 0
            ) {
              setColumnOrder(jsonFilterData.columnOrder);
            }

            //check date range type is not empty
            if (jsonFilterData.fromDate) {
              setfilterStartDate(jsonFilterData.fromDate);
            } else {
              setfilterStartDate("");
            }

            if (jsonFilterData.toDate) {
              setfilterEndDate(jsonFilterData.toDate);
            } else {
              setfilterEndDate("");
            }

            if (jsonFilterData.logOwnerIds) {
              setfilterUserIds(jsonFilterData.logOwnerIds);
            } else {
              setfilterUserIds([]);
            }

            if (jsonFilterData.projectIds) {
              setfilterProjectIds(jsonFilterData.projectIds);
            } else {
              setfilterProjectIds([]);
            }

            if (
              jsonFilterData.tagValues &&
              jsonFilterData.tagValues.length > 0
            ) {
              setFilterTags(jsonFilterData.tagValues);
            } else {
              setFilterTags([]);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    //     settitle("");
    setfromDate("");
    settoDate("");
    settagValues([]);

    setlogOwnerInput("");
    setlogOwnerOptions([]);

    setlogOwnerValue(null);
    setlogOwnerIds([]);

    setprojectValue(null);
    setprojectIds([]);
    //     setuserValue(null);
    //     setuserIds([]);
    //     settaskValue(null);
    //     settaskIds([]);

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    //  getAllTags();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  useEffect(() => {
    if (projectInput.length > 1) {
      getProjectList();
    }
  }, [projectInput]);

  useEffect(() => {
    if (logOwnerInput.length > 3) {
      getLogOwnerList();
    }
  }, [logOwnerInput]);

  const projectOptionsToShow = projectList.length > 0 ? projectList : [];

  const logOwnerOptionsToShow =
    logOwnerOptions.length > 0 ? logOwnerOptions : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasLogFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* dates */}
          <div className="form-group mb-4">
            <label htmlFor="Dates" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* project */}
          <div className="form-group mb-4">
            <label htmlFor="Project" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Project")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Type Project name")}
              options={projectOptionsToShow}
              value={projectValue}
              onChange={(val) => {
                projectSelectionHandler(val);
              }}
              onInputChange={(val) => {
                projectInputHandler(val);
              }}
            />
          </div>

          {/* log owner */}
          <div className="form-group mb-4">
            <label
              htmlFor="Added By (log owner)"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Added By (log owner)")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Select User")}
              options={logOwnerOptionsToShow}
              value={logOwnerValue}
              onChange={(val) => {
                logOwnerSelectionHandler(val);
              }}
              onInputChange={(val) => {
                logOwnerInputHandler(val);
              }}
            />
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* --------- Labels ------------- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />

                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => logTagSelectionHandler(tagData)}
                            id={"projectlogtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}

          {/* <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default LogFilterPopup;
