/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
const ListFilterPopup = ({
  tagList,
  listTypeOptions,
  setfilterTitle,
  setfilterTypes,
  setfilterTags,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [title, settitle] = useState("");
  const [typevalues, settypevalues] = useState([]);
  const [tagValues, settagValues] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //function for select types
  const listTypeSelectionHandler = (data) => {
    let prevTypeValues = typevalues;
    if (prevTypeValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "listtypefilter-" + data.value
      );
      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTypeValues.indexOf(data.value);
      prevTypeValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "listtypefilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTypeValues.push(data.value);
    }
    settypevalues(prevTypeValues);
  };
  //function for select tags
  const listTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;
    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "listtagfilter-" + data.value
      );
      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "listtagfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };
  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };
  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setfilterTitle(title);
    setfilterTags(tagValues);
    setfilterTypes(typevalues);
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasListFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };
  const resetHandler = () => {
    removeActiveClass();
    settitle("");
    settypevalues([]);
    settagValues([]);
  };
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasListFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* title */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Search by Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Search by Title")}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* by type */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Search by Type")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {listTypeOptions.map((listData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => listTypeSelectionHandler(listData)}
                            id={"listtypefilter-" + listData.value}
                            className="typeblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{listData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* by label */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Search by label")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />

                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => listTagSelectionHandler(tagData)}
                            id={"listtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};
export default ListFilterPopup;
