import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

import {createdAtToDateConverter} from "helper/Common/CommonHelper";

import { getData, postData} from "utils/Gateway";

const ColumnItem = (props) => {
  
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

 
  

  const { id, 
          item, 
          setReloadData,
          setSelectedComponentId, 
          setSelectedTagId,
          selectedItemEmails,
          setSelectedItemEmails 
        } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 2,
    margin: 2,
    background: "#fff",
    //border: "1px solid #ccc"
  };

  // delete item
  const deleteComponentOrder = async(id) => {

    console.log("Delete Item: ", id);


    if (!window.confirm("Are you sure you want to delete this item?")) {
      return;
    }

    // set json data for each column ---------------------
    const columnItemsURL = url.API_BASE_URL + 
                          url.API_DELETE_COMPONENTORDER + 
                          `/${id}?token=${token}`;

    console.log('columnItemsURL', columnItemsURL);

    const response = await getData(columnItemsURL);

    console.log('deleteComponentOrderResponse', response);
    
    if(response.status){
      setReloadData(true);
    }   
    

  }

  //create chat
  const createContactChatHandler = async (
      userId = null,
      componentTitle = ""
    ) => {
      if (userId && userId.toString() !== userInfo._id.toString()) {
        try {
          let requestURL =
            url.API_BASE_URL +
            url.API_SEARCH_CONTACT +
            `?token=${token}&member=${userId}`;
  
          const response = await getData(requestURL);
  
          console.log(response);
  
          if (response.status) {
            const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/`;
            // const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT`; // Replace with your external URL
  
            window.open(externalUrl, "_blank", "noopener,noreferrer");
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
      }
    };

  const viewItemDetails = (itemid) =>{

    console.log('viewItemDetails for id', itemid);

    setSelectedComponentId(itemid);
    setSelectedTagId(null);

  }


  



  return (
    // Details about the lead - single card item
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

      <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">

        <div className="get-code-row d-flex align-items-center justify-content-between mb-2">
          
          <div className="d-flex align-items-center gap-2">
            
            <p
              className="fs-md fw-semibold mb-0 d-flex"
              onPointerDown={(e) => {
                e.stopPropagation();
              }} 
            >
              <input
                type="checkbox"
                checked={selectedItemEmails && selectedItemEmails.includes(item.lead?.email)}
                onChange={(e) => {
                  setSelectedItemEmails((prev) => {
                    if (e.target.checked) {
                      // Verify email exists and isn't already added
                      const email = item.lead?.email;

                      console.log('email', email);
                      console.log('prev', prev);

                      if (email && !prev.includes(email)) {
                        return [...prev, email];
                      }
                      return prev;
                    } else {
                      return prev.filter((email) => email !== item.lead?.email);
                    }
                  });
                }}
              /> 
            </p>

            <p className="fs-md fw-semibold mb-0 d-flex" 
            title="Referenece id"
            onPointerDown={(e) => {
                e.stopPropagation();
                viewItemDetails(item.lead._id)
              }}           
            >
              

              {item.lead?.customid??''}
              <span className="d-block material-symbols-outlined icon-md ms-2">
                edit
              </span>

            </p>
          </div>

          <div className="menu_info">

            <button
              className="p-0 bg-transparent border-0 ms-2"
              type="button"
              onPointerDown={(e) => {
                e.stopPropagation();
                deleteComponentOrder(item.id);
              }}
            >
              <span className="d-block material-symbols-outlined icon-md text-danger">
                delete
              </span>
            </button>

          </div>

        </div>
        
        <div className="card-user-info d-flex align-items-center gap-2 mb-2">
          <span className="d-block overflow-hidden rounded-circle card-usr">
            <img
              src={
                item.lead.photoimage && item.lead.photoimage.path
                  ? url.SERVER_URL + item.lead.photoimage.path
                  : assetImages.defaultUser
              }
              alt="User"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </span>
          <h4 className="mb-0 text-black">
            {item.label}
            <span className="d-block mt-1 text-gray d-flex" title="Company associated">
              <i className="d-block material-symbols-outlined icon-md me-1">
                business
              </i>
              {item.lead?.company??''}
            </span>
          </h4>
        </div>

        <div className="date-row d-flex align-items-center gap-2">
          <h5 className="d-flex align-items-center gap-1 fs-sm">
            <span className="d-block material-symbols-outlined icon-md text-gray">calendar_today</span>
            <span className="d-block" title="Date when the item is added in the board">
              {item.created?createdAtToDateConverter(item.created):''}
            </span>
          </h5>
          -
          <h5 className="d-flex align-items-center gap-1 fs-sm">
            <span className="d-block material-symbols-outlined icon-md text-gray">calendar_today</span>
            <span className="d-block" title="Date when the item is added in this column" >
              
              {item.updated?createdAtToDateConverter(item.updated):''}
            </span>
          </h5>
        </div>
        
        <div>
          <Link to="#" className="d-flex align-items-center gap-1">
            <span className="d-block material-symbols-outlined icon-md text-gray">mail</span>
            <span className="d-block">{item.lead?.email??''}</span>
          </Link>
          <Link to="#" className="d-flex align-items-center gap-1">
            <span className="d-block material-symbols-outlined icon-md text-gray">phone</span>
            <span className="d-block">{item.lead?.phone??''}</span>
          </Link>
        </div>

        <div className="d-flex align-items-center gap-2">

          <span className="fw-semibold"  title="Moderator">M</span> :

          <div className="profile d-flex align-items-left">
            
            <i> 
                {item.lead?.moderatorDetails?.name} 
                {' '} 
                {item.lead?.moderatorDetails?.surname}
            </i>
  
            {item.lead?.moderatorDetails?._id?.toString() !== userInfo._id.toString() ? (
              <button
                title="click to chat"
                onClick={() => {
                  //createContactChatHandler(item.lead._id, item.lead.name);
                }}
                className="d-flex ms-auto border-0 bg-light"
              >
                <span className="d-block material-symbols-outlined icon-md text-primary">
                  chat
                </span>
              </button>
            ):''}
          </div>

        </div>
        
        
        {item.lead?.moderatorDetails?._id?.toString() === userInfo._id.toString() && 
          item.lead.followersDetails && 
          item.lead.followersDetails.length > 0 ? (
            <div className="d-flex align-items-center gap-2">
              <span className="fw-semibold"  title="Followers">F</span> :
          
              <div className="profile d-flex flex-column align-items-left">
                {item.lead.followersDetails.map((follower, index) => {
                  return (
                    <div className="profile d-flex" key={index}>
            
                      <i>{follower?.name} {' '} {follower?.surname}</i>
            
                      {follower?._id?.toString() !== userInfo._id.toString() ? (
                        <button
                          title="click to chat"
                          onClick={() => {
                            //createContactChatHandler(item.lead._id, item.lead.name);
                          }}
                          className="d-flex ms-auto border-0 bg-light"
                        >
                          <span className="d-block material-symbols-outlined icon-md text-primary">
                            chat
                          </span>
                        </button>
                      ):''}
                    </div>
                  );
                })}
              </div>
              
            </div>
        ) : null}

        {/* <p className="price fw-semibold text-primary my-1">$259.00</p> */}

      </div>      


    </div>





  );
};

export default ColumnItem;