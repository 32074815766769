/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import SubscribeOptions from "data/Prod/SubscribeOptions.json";
import FilterDateOptions from "data/Prod/FilterLeadDateBy.json";

import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const CrmLeadKanbanFilterPopup = ({
  moduleName,
  filterSubsribeStatus,
  setFilterSubsribeStatus,
  filterStartDate,
  setfilterStartDate,
  filterEndDate,
  setfilterEndDate,
  filterLeadName,
  setFilterLeadName,
  filterEmail,
  setFilterEmail,
  filterLocation,
  setFilterLocation,
  filterPhone,
  setFilterPhone,
  filterCompany,
  setFilterCompany,
  filterPosition,
  setFilterPosition,
  filterTransactionModule,
  setFilterTransactionModule,
  filterTransactionElement,
  setFilterTransactionElement,
  filterSkills,
  setFilterSkills,
  filterMinSalary,
  setFilterMinSalary,
  filterMaxSalary,
  setFilterMaxSalary,
  isFilterReset,
  setIsFilterReset,
  setIsNoDefaultFilter,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* Language translation imports starts here */
  const { t } = useTranslation();
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  /* filter requirements */
  const [skillList, setSkillList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leadName, setLeadName] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [leadPhone, setLeadPhone] = useState("");
  const [locationName, setLocationName] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [transactionModule, settransactionModule] = useState("");
  const [transactionElement, settransactionElement] = useState("");
  const [skills, setSkills] = useState([]);
  const [minSal, setMinSal] = useState("");
  const [maxSal, setMaxSal] = useState("");
  const [subscribeMailValue, setsubscribeMailValue] = useState(null);
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("");
  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all skills
  const getAllSkills = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);
      setSkillList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //check date range type is not empty
      if (jsonFilterData.startDate) {
        setStartDate(jsonFilterData.startDate);
      }

      if (jsonFilterData.endDate) {
        setEndDate(jsonFilterData.endDate);
      }

      if (jsonFilterData.leadName) {
        setLeadName(jsonFilterData.leadName);
      }

      if (jsonFilterData.leadEmail) {
        setLeadEmail(jsonFilterData.leadEmail);
      }

      if (jsonFilterData.leadPhone) {
        setLeadPhone(jsonFilterData.leadPhone);
      }

      if (jsonFilterData.locationName) {
        setLocationName(jsonFilterData.locationName);
      }

      if (jsonFilterData.companyValue) {
        companySelectionHandler(jsonFilterData.companyValue);
      }

      if (jsonFilterData.position) {
        setPosition(jsonFilterData.position);
      }

      if (jsonFilterData.transactionModule) {
        settransactionModule(jsonFilterData.transactionModule);
      }

      if (jsonFilterData.transactionElement) {
        settransactionElement(jsonFilterData.transactionElement);
      }

      if (jsonFilterData.skills) {
        // Filtering the skills options based on values present in the json array
        const filteredSkills = skillList.filter((item) =>
          jsonFilterData.skills.includes(item.value)
        );

        //run loop
        filteredSkills.forEach((item) => {
          skillSelectionHandler(item);
        });
      }

      if (jsonFilterData.subscribeMailValue) {
        subscribeSelectionHandler(jsonFilterData.subscribeMailValue);
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select subscribe
  const subscribeSelectionHandler = (val) => {
    if (val) {
      setsubscribeMailValue(val);
      setSubscribeMailStatus(val.value);
    } else {
      setsubscribeMailValue(null);
      setSubscribeMailStatus("");
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyName(val.value);
    } else {
      setCompanyValue(null);
      setCompanyName("");
    }
  };

  // function for set tags
  const skillSelectionHandler = (data) => {
    let prevSkills = skills;
    if (prevSkills.includes(data.value)) {
      let selectedTagItem = document.getElementById(
        "skillfilter-" + data.value
      );
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevSkills.indexOf(data.value);
      prevSkills.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "skillfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevSkills.push(data.value);
    }
    setSkills(prevSkills);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected skills
  const resetSelectSkills = () => {
    let resetBlock = document.getElementsByClassName("skillblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");

      if(filterStartDate !== undefined){
        if (startDate !== "" && endDate !== "") {
          setfilterStartDate(startDate);
          setfilterEndDate(endDate);
        } else {
          setfilterStartDate(filterStartDate);
          setfilterEndDate(filterEndDate);
        }      
      }

      
      
      if(filterSubsribeStatus !== undefined){

        if (subscribeMailStatus !== "") {
          setFilterSubsribeStatus(subscribeMailStatus);
        } else {
          setFilterSubsribeStatus(filterSubsribeStatus);
        }

      }

      if(filterLeadName !== undefined){
        if (leadName !== "") {
          setFilterLeadName(leadName);
        } else {
          setFilterLeadName(filterLeadName);
        }
      }
      
      if(filterEmail !== undefined){
        if (leadEmail !== "") {
          setFilterEmail(leadEmail);
        } else {
          setFilterEmail(filterEmail);
        }
      }

      if(filterLocation !== undefined){
        if (locationName !== "") {
          setFilterLocation(locationName);
        } else {
          setFilterLocation(filterLocation);
        }
      }

      if(filterPhone !== undefined){
        if (leadPhone !== "") {
          setFilterPhone(leadPhone);
        } else {
          setFilterPhone(filterPhone);
        }
      }

      if(filterCompany !== undefined){
        if (companyName !== "") {
          setFilterCompany(companyName);
        } else {
          setFilterCompany(filterCompany);
        }
      }

      if(filterPosition != undefined){
        if (position !== "") {
          setFilterPosition(position);
        } else {
          setFilterPosition(filterPosition);
        } 
      }

      if(filterTransactionModule !== undefined){
        if (transactionModule !== "") {
          setFilterTransactionModule(transactionModule);
        } else {
          setFilterTransactionModule("");
        }
      }

      if(filterTransactionElement !== undefined){
        if (transactionElement !== "") {
          setFilterTransactionElement(transactionElement);
        } else {
          setFilterTransactionElement("");
        }
      }

      
      if(filterSkills !== undefined){
        if (skills.length > 0) {
          setFilterSkills(skills);
        } else {
          setFilterSkills([]);
        }
      }

      if(filterMinSalary !== undefined && filterMaxSalary !== undefined){
        if (minSal !== "") {
          setFilterMinSalary(minSal);
        } else {
          setFilterMinSalary(filterMinSalary);
        }

        if (maxSal !== "") {
          setFilterMaxSalary(maxSal);
        } else {
          setFilterMaxSalary(filterMaxSalary);
        }
      }       


      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector(
        "#offcanvasCRMLeadKanbanFilter"
      );

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            leadName: leadName,
            leadEmail: leadEmail,
            leadPhone: leadPhone,
            locationName: locationName,
            companyValue: companyValue,
            companyName: companyName,
            position: position,
            transactionModule: transactionModule,
            transactionElement: transactionElement,
            skills: skills,

            minSal: minSal,
            maxSal: maxSal,
            subscribeMailValue: subscribeMailValue,
            subscribeMailStatus: subscribeMailStatus,
          }),
          moduleslug: moduleName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(true);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            //check date range type is not empty
            if (jsonFilterData.startDate) {
              setfilterStartDate(jsonFilterData.startDate);
            } else {
              setfilterStartDate(filterStartDate);
            }

            if (jsonFilterData.endDate) {
              setfilterEndDate(jsonFilterData.endDate);
            } else {
              setfilterEndDate(filterEndDate);
            }

            if (jsonFilterData.leadName) {
              setFilterLeadName(jsonFilterData.leadName);
            } else {
              setFilterLeadName(filterLeadName);
            }

            if (jsonFilterData.leadEmail) {
              setFilterEmail(jsonFilterData.leadEmail);
            } else {
              setFilterEmail(filterEmail);
            }

            if (jsonFilterData.leadPhone) {
              setFilterPhone(jsonFilterData.leadPhone);
            } else {
              setFilterPhone(filterPhone);
            }

            if (jsonFilterData.locationName) {
              setFilterLocation(jsonFilterData.locationName);
            } else {
              setFilterLocation(filterLocation);
            }

            if (jsonFilterData.companyName) {
              setFilterCompany(jsonFilterData.companyName);
            } else {
              setFilterCompany(filterCompany);
            }

            if (jsonFilterData.position) {
              setFilterPosition(jsonFilterData.position);
            } else {
              setFilterPosition(filterPosition);
            }

            if (jsonFilterData.transactionModule) {
              setFilterTransactionModule(jsonFilterData.transactionModule);
            } else {
              setFilterTransactionModule("");
            }

            if (jsonFilterData.transactionElement) {
              setFilterTransactionElement(jsonFilterData.transactionElement);
            } else {
              setFilterTransactionElement("");
            }

            if (jsonFilterData.skills.length > 0) {
              setFilterSkills(jsonFilterData.skills);
            } else {
              setFilterSkills(filterSkills);
            }

            if (jsonFilterData.minSal) {
              setFilterMinSalary(jsonFilterData.minSal);
            } else {
              setFilterMinSalary(filterMinSalary);
            }

            if (jsonFilterData.maxSal) {
              setFilterMaxSalary(jsonFilterData.maxSal);
            } else {
              setFilterMaxSalary(filterMaxSalary);
            }

            if (jsonFilterData.subscribeMailStatus) {
              setFilterSubsribeStatus(jsonFilterData.subscribeMailStatus);
            } else {
              setFilterSubsribeStatus(filterSubsribeStatus);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setValidation({ dateWarning: false });
    setsubscribeMailValue(null);
    setSubscribeMailStatus("");
    setStartDate("");
    setEndDate("");
    setLeadName("");
    setLeadEmail("");
    setLeadPhone("");
    setLocationName("");
    setcompanyInput("");
    setCompanyList([]);
    setCompanyValue(null);
    setCompanyName("");
    setPosition("");
    settransactionModule("");
    settransactionElement("");
    setSkills([]);
    setMinSal("");
    setMaxSal("");
    removeActiveClass();
    resetSelectSkills();

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  /* use effect for get company list by typing company name */
  useEffect(() => {
    if (companyInput.length > 0) {
      getCompanyList();
    }
  }, [companyInput]);

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    getAllSkills();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  //options for company list in select
  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCRMLeadKanbanFilter"
      aria-labelledby="offcanvasCRMLeadKanbanFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        {/* <!-- ========== Start popup close button Section ========== --> */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* <!-- ========== End popup close button Section ========== --> */}
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* <!-- ========== Start subscribe Section ========== --> */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Is Subscribe to Mail")}</h3>{" "}
            </label>
            <Select
              placeholder={t("Not Selected")}
              isClearable
              options={SubscribeOptions}
              value={subscribeMailValue}
              onChange={(val) => {
                subscribeSelectionHandler(val);
              }}
            />
          </div>
          {/* <!-- ========== End subscribe Section ========== --> */}

          
          {/* <!-- ========== Start date range Section ========== --> */}
          {filterStartDate !== undefined && (
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t(" Created Date")}</h3>
              </label>

              <div className="d-flex gap-2 ">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="Start date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="End date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              {validation.dateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
            </div>
          )}

          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start name Section ========== --> */}
           {filterLeadName !== undefined && (
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Name")}</h3>{" "}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={leadName}
                onChange={(e) => setLeadName(e.target.value)}
              />
            </div>
          )}
          {/* <!-- ========== End name Section ========== --> */}

          {/* <!-- ========== Start email Section ========== --> */}
          {filterEmail !== undefined && (
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Email")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Email")}
              value={leadEmail}
              onChange={(e) => setLeadEmail(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End email Section ========== --> */}

          {/* <!-- ========== Start phone Section ========== --> */}
          {filterPhone !== undefined && (
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Phone")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Phone")}
              value={leadPhone}
              onChange={(e) => setLeadPhone(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End phone Section ========== --> */}

          {/* <!-- ========== Start company Section ========== --> */}
          {filterCompany !== undefined && (
          <div className="form-group mb-4">
            <label htmlFor="Company" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Company")}</h3>{" "}
            </label>
            <CreatableSelect
              placeholder={t("Type Company name")}
              isClearable
              options={companyOptionsToShow}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setcompanyInput(val);
              }}
            />
          </div>
          )}
          {/* <!-- ========== End company Section ========== --> */}

          {/* <!-- ========== Start position Section ========== --> */}
          {filterPosition !== undefined && (
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Position")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Position")}
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End position Section ========== --> */}

          

          {/* <!-- ========== Start location Section ========== --> */}
          {filterLocation !== undefined && (
          <div className="form-group mb-4">
            <label
              htmlFor="Address"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Address")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Address")}
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End location Section ========== --> */}

          {/* <!-- ========== Start salary Section ========== --> */}
          {filterMinSalary !== undefined && (
          <div className="form-group mb-4">
            <div className="row">
              {/* <!-- ========== Start min salary Section ========== --> */}
              <div className="col">
                <div className="form-group">
                  <label htmlFor="salary" className="d-block fs-sm mb-2">
                    {t("Min Salary")}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter min salary")}
                      value={minSal}
                      onChange={(e) => setMinSal(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- ========== End min salary Section ========== --> */}

              {/* <!-- ========== Start max salary Section ========== --> */}
              <div className="col">
                <div className="form-group">
                  <label htmlFor="salary" className="d-block fs-sm mb-2">
                    {t("Max Salary")}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter max salary")}
                      value={maxSal}
                      onChange={(e) => setMaxSal(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- ========== End max salary Section ========== --> */}
            </div>
          </div>
          )}
          {/* <!-- ========== End salary Section ========== --> */}

          {/* <!-- ========== Start transaction module Section ========== --> */}
          {filterTransactionModule !== undefined && (
          <div className="form-group mb-4">
            <label
              htmlFor="TransactionModule"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Transaction Module")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Module")}
              value={transactionModule}
              onChange={(e) => settransactionModule(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End transaction module Section ========== --> */}

          {/* <!-- ========== Start transaction element Section ========== --> */}
          {filterTransactionElement !== undefined && (
          <div className="form-group mb-4">
            <label
              htmlFor="transactionElement"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Transaction Element")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Element")}
              value={transactionElement}
              onChange={(e) => settransactionElement(e.target.value)}
            />
          </div>
          )}
          {/* <!-- ========== End transaction element Section ========== --> */}
          {filterSkills !== undefined && (
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start skills accordion Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  {t("Skills")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {skillList.map((skill, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => skillSelectionHandler(skill)}
                            id={"skillfilter-" + skill.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{skill.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End skills accordion Section ========== --> */}
          </div>
          )}

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default CrmLeadKanbanFilterPopup;
