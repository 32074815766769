/* eslint-disable */
import { Link } from "react-router-dom";
import { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const BlogFilterPopup = ({
  categoryList,
  tagList,
  setFilterDateRange,
  setFilterBlogTitle,
  setFilterBlogCategory,
  setFilterBlogLabels,
}) => {
  const { t } = useTranslation(); //for translation
  const [blogFromDate, setblogFromDate] = useState("");
  const [blogToDate, setblogToDate] = useState("");
  const [blogTitle, setblogTitle] = useState("");
  const [selectedBlogCategory, setselectedBlogCategory] = useState([]);
  const [selectedBlogLabels, setselectedBlogLabels] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //function for category selection
  const categorySelectionHandler = (data) => {
    let prevBlogCategory = selectedBlogCategory;

    if (prevBlogCategory.includes(data.value)) {
      let selectedItem = document.getElementById(
        "blogcategoryfilter-" + data.value
      );
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevBlogCategory.indexOf(data.value);
      prevBlogCategory.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "blogcategoryfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevBlogCategory.push(data.value);
    }
    setselectedBlogCategory(prevBlogCategory);
  };

  // function for set tags
  const tagselectionHandler = (data) => {
    let prevTags = selectedBlogLabels;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById(
        "blogtagfilter-" + data.value
      );
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "blogtagfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }

    setselectedBlogLabels(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected category
  const resetSelectCategory = () => {
    let resetBlock = document.getElementsByClassName("blogcategoryblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("blogtagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (blogFromDate != "" && blogToDate != "" && blogFromDate < blogToDate) {
      setFilterDateRange(blogFromDate + "," + blogToDate);
    } else if (blogFromDate != "") {
      setFilterDateRange(blogFromDate);
    }

    setFilterBlogTitle(blogTitle);
    setFilterBlogCategory(selectedBlogCategory);
    setFilterBlogLabels(selectedBlogLabels);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasBlogFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setblogFromDate("");
    setblogToDate("");
    setblogTitle("");
    setselectedBlogCategory([]);
    setselectedBlogLabels([]);
    removeActiveClass();
    resetSelectCategory();
    resetSelectTags();
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasBlogFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        {/* ----- search form section start ------ */}
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            {/* ------ accordion for created date range start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Created Dates")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <div className="row gx-2">
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder={t("From date")}
                          value={blogFromDate}
                          onChange={(e) => setblogFromDate(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder={t("To date")}
                          value={blogToDate}
                          onChange={(e) => setblogToDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ accordion for created date range end ------ */}

            {/* ------ accordion for blog title start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTitle"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTitle"
                >
                  {t("Blog Title")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTitle"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Blog Title")}
                      value={blogTitle}
                      onChange={(e) => setblogTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ------ accordion for blog title end ------ */}

            {/* ------ accordion for category start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Category")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {categoryList.map((category, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => categorySelectionHandler(category)}
                            id={"blogcategoryfilter-" + category.value}
                            className="blogcategoryblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{category.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ------ accordion for category end ------ */}

            {/* ------ accordion for labels start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagselectionHandler(tag)}
                            id={"blogtagfilter-" + tag.value}
                            className="blogtagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ------ accordion for labels end ------ */}
          </div>
          {/* ----- search button start ----- */}
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Go")}
          </button>
          {/* ----- search button end ----- */}
        </form>
        {/* ----- search form section start ------ */}
      </div>
    </div>
  );
};

export default BlogFilterPopup;
