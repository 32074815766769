/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// modules for process
import ProcessModuleOptions from "data/Prod/ProcessModuleOptions.json";

//import context
import { GlobalProvider } from "context/GlobalContext";

const SaveActivityProcessPopup = ({
  afterPopupClose,
  selectedProcessId,
  setSelectProcessId,
  isTemplate,
  moduleSlug,
  setShowAlert,
  setAlertMessage,
  setMessageType,
  requestBoardUpdate,
  setRequestBoardUpdate
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);

  const [title, settitle] = useState("");
  const [slug, setslug] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);

  const [followersOptions, setFollowersOptions] = useState([]);
  const [followersValue, setFollowersValue] = useState(null);
  const [selectedFollowers, setselectedFollowers] = useState([]);
  const [followersInput, setFollowersInput] = useState("");

  const [moduleValue, setModuleValue] = useState(null);
  const [selectedModule, setSelectedModule] = useState(moduleSlug);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    slugWarning: false,
    // tagWarning: false,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    // if (selectedTags.length === 0) {
    //   setValidation((prevState) => ({ ...prevState, tagWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TAGS +
        `?token=${token}&modulename=${moduleSlug}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setFollowersOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //select followers
  const followersSelectionHandler = (val) => {
    setFollowersValue(val);
    setselectedFollowers(val.map((item) => item.value));
  };

  //function for get followers input
  const followersInputHandler = (val) => {
    setFollowersInput(val);
  };

  //select module
  const moduleSelectionHandler = (val) => {
    if (val) {
      setModuleValue(val);
      setSelectedModule(val.value);
    } else {
      setModuleValue(null);
      setSelectedModule("");
    }
  };

  //select tags
  const tagSelectionHandler = (val) => {
    if (val) {
      settagValue(val);
      setselectedTags(
        val.map((item) => ({
          tagid: item.value,
          componentorderid: item?.componentorderid ?? null,
        }))
      );
    } else {
      settagValue(null);
      setselectedTags([]);
    }
  };

  //get process details
  const getProcessDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_PROCESS_DETAILS +
        `/${selectedProcessId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status && response.data) {
        settitle(response.data.title);

        setslug(response.data.slug);

        if (response.data.tagvalues.length > 0) {
          tagSelectionHandler(response.data.tagvalues);
        }

        if (response.data.followersArr) {
          followersSelectionHandler(response.data.followersArr);
        }

        for (let moduleData of ProcessModuleOptions) {
          if (moduleData.value === response.data.moduleslug) {
            moduleSelectionHandler(moduleData);
          }
        }

      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //save process
  const saveProcessHandler = async () => {
    if (validate()) {
      seterrorMessage("");

      try {
        setIsSaving(true);

        let processData = {
          title,
          slug: slug.toUpperCase(),
          moduleslug: selectedModule,
          istemplate: isTemplate,
          tags: selectedTags,
          followers: selectedFollowers,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedProcessId) {
          requestURL +=
            url.API_UPDATE_PROCESS + `/${selectedProcessId}?token=${token}`;

          response = await putData(requestURL, processData);
        } else {
          requestURL += url.API_ADD_NEW_PROCESS + `?token=${token}`;

          response = await postData(requestURL, processData);
        }

        setIsSaving(false);

        // console.log(response);

        if (response.status) {
          setMessageType("success");
          resetHandler();
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#activityProcessOffCanvas")
          ).hide();
          afterPopupClose();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      seterrorMessage(t("Please fill up required fields"));
      setMessageType("error");
      setAlertMessage("Please fill up required fields");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setRequestBoardUpdate(false);
    settitle("");
    setslug("");
    settagValue(null);
    setselectedTags([]);
    setValidation({
      titleWarning: false,
      slugWarning: false,
      tagWarning: false,
    });

    setModuleValue(null);
    setSelectedModule("");
    setFollowersValue(null);
  
  };

  useEffect(() => {
    if (selectedProcessId && requestBoardUpdate) {
      getProcessDetails();
    }
  }, [selectedProcessId, requestBoardUpdate]);


  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersList();
    }
  }, [followersInput]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="activityProcessOffCanvas"
      aria-labelledby="activityProcessOffCanvas"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="activityProcessOffCanvas">
          {t("Save Activity Process")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            {/* title */}
            <div className="form-group mb-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                {t("Process Name")}
              </label>
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                  
                  
                  const newSlug = e.target.value
                    .trim()
                    .toLowerCase()
                    .replace(/\s+/g, '-');

                  setslug(newSlug);

                }}
              />
              {/* title warning */}
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter name")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setslug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* tags */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Labels")}
              </label>
              <Select
                isMulti
                placeholder={t("Select tags")}
                options={tagList}
                value={tagValue}
                onChange={(val) => {
                  tagSelectionHandler(val);
                  // setValidation((prevState) => ({
                  //   ...prevState,
                  //   tagWarning: false,
                  // }));
                }}
              />

              {/* {validation.tagWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please select tags!</span>
                  </p>
                </div>
              )} */}
            </div>

            {/* <!-- ========== Start followers Section ========== --> */}
         
              <div className="form-group mb-4">
                <label
                  htmlFor="followers"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Followers")}
                </label>
                <Select
                  isMulti
                  placeholder={t("Type user name or email")}
                  options={followersOptions}
                  value={followersValue}
                  onChange={(val) => followersSelectionHandler(val)}
                  onInputChange={(val) => {
                    followersInputHandler(val);
                  }}
                />
              </div>
         
            {/* <!-- ========== End followers Section ========== --> */}


            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select Module")}
              </label>
              <Select
                isClearable
                placeholder={t("Select Module")}
                options={ProcessModuleOptions}
                value={moduleValue}
                onChange={(val) => moduleSelectionHandler(val)}
              />
            </div>

          </div>


          <div className="action d-flex justify-content-between gap-3">
            <div className="d-flex align-items-center gap-2">
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetHandler}
              >
                {t("Close")}
              </Link>
              {errorMessage === "" ? null : (
                <div className="error-message">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{errorMessage}!</span>
                  </p>
                </div>
              )}
            </div>
            <button
              onClick={saveProcessHandler}
              type="button"
              className="btn btn-primary"
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>


        </form>
      </div>
    </div>
  );
};

export default SaveActivityProcessPopup;
