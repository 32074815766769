/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
  deleteData,
} from "utils/Gateway";
import languageOptions from "data/Prod/LanguageOptions.json";

const CourseListFilterPopup = ({
  setFilterStartDate,
  setFilterEndDate,
  setFilterTitle,
  setFilterCode,
  setFilterLanguage,
  setFilterTags,
  functionCall,
}) => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem("token");

  const [languageValue, setlanguageValue] = useState(null);

  const [selectedLanguage, setselectedLanguage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [tagList, setTagList] = useState([]);
  const [tags, setTags] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //   function for getting all tags
  const getAllTags = async () => {
    try {
      // Construct the request URL by appending the token to the API endpoint.
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      // Make a GET request to the API to retrieve all tags.
      const response = await getData(requestUrl);

      // Log the response from the API.
      console.log(response);

      // If the response is successful, update the state with the retrieved tags.
      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      // Log any error that occurs during the API call.
      console.log(error.message);
    }
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (startDate === "" && endDate !== "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (endDate !== "" && endDate < startDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for select language
  const languaSelectionHandler = (val) => {
    if (val) {
      setlanguageValue(val);
      setselectedLanguage(val.value);
    } else {
      setlanguageValue(null);
      setselectedLanguage("");
    }
  };

  // function for select tags
  const tagSelectionHandler = (data) => {
    let prevTags = tags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");

      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");

      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  // function for apply filter
  const applyFilterHandler = () => {
    if (validationHandler()) {
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
      setFilterTitle(title);
      setFilterCode(code);
      setFilterLanguage(selectedLanguage);
      setFilterTags(tags);
      functionCall();

      let offCanvasPopup = document.querySelector("#offcanvasFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  // function for reset
  const resetHandler = () => {
    setselectedLanguage("");
    setStartDate("");
    setEndDate("");
    setTitle("");
    setCode("");
    setTags([]);

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  // useEffect for getting all tags
  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        {/* <!-- ========== Start popup close button Section ========== --> */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* <!-- ========== End popup close button Section ========== --> */}
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start date range Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                }}
              />
            </div>
            {validation.endDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
            {validation.startDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter start date!</span>
                </p>
              </div>
            )}
            {validation.endDateSmallerWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>End date should not be greater than start date!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start title Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Title" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Title")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* <!-- ========== End title Section ========== --> */}

          {/* <!-- ========== Start code Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Code" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Code")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Code")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {/* <!-- ========== End code Section ========== --> */}

          {/* <!-- ========== Start language Section ========== --> */}
          <div className="form-group mb-4">
            <label
              htmlFor="Language"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Language")}</h3>{" "}
            </label>
            <Select
              placeholder={t("Select Language")}
              options={languageOptions}
              value={languageValue}
              onChange={(val) => {
                languaSelectionHandler(val);
              }}
            />
          </div>
          {/* <!-- ========== End language Section ========== --> */}

          {/* ============ Start skills/tags Section ============ */}
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start label/tag accordion Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapselabels"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapselabels"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapselabels"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tag)}
                            id={"tagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End label/tag accordion Section ========== --> */}
          </div>

          {/* <!-- ========== Start error message Section ========== --> */}
          {/* {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )} */}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {/* {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )} */}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={applyFilterHandler}
          >
            {t("Apply filter")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseListFilterPopup;
