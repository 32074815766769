/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import module options
import NoteModuleListOptions from "data/Prod/NoteModuleList.json";

import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const NoteListFilterPopup = ({
  visibleColoumns,
  setVisibleColoumns,
  columnOrder,
  setColumnOrder,
  moduleName,

  setFilterStartDate,
  setFilterEndDate,
  setFilterTitle,
  setFilterLead,
  setFilterContact,
  setFilterOpportunity,
  setFilterOwner,
  setFilterTags,
  isFilterReset,
  setIsFilterReset,
  setIsNoDefaultFilter,

  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* Language translation imports starts here */
  const { t } = useTranslation();
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const NoteModuleList = NoteModuleListOptions.map((item) => {
    return {
      value: item.value,
      label: t(item.label),
    };
  });

  const userObj = {
    label: `${userInfo.name ?? userInfo.email} ${userInfo.surname ?? ""}`,
    value: userInfo._id,
  };

  const [tagList, setTagList] = useState([]);

  const [selectedModuleType, setSelectedModuleType] = useState(null);

  const [leadInput, setleadInput] = useState("");
  const [contactInput, setcontactInput] = useState("");
  const [opportunityInput, setOpportunityInput] = useState("");
  const [noteOwnerInput, setnoteOwnerInput] = useState("");

  const [leadOptions, setleadOptions] = useState([]);
  const [contactOptions, setcontactOptions] = useState([]);
  const [opportunityOptions, setOpportunityOptions] = useState([]);
  const [noteOwnerOptions, setnoteOwnerOptions] = useState([userObj]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noteLeadValue, setNoteLeadValue] = useState(null);
  const [noteLeadId, setNoteLeadId] = useState(null);
  const [noteContactValue, setNoteContactValue] = useState(null);
  const [noteContactId, setNoteContactId] = useState(null);
  const [noteOpportunityValue, setNoteOpportunityValue] = useState(null);
  const [noteOpportunityId, setNoteOpportunityId] = useState(null);
  const [noteOwnerValue, setNoteOwnerValue] = useState(null);
  const [noteOwnerId, setNoteOwnerId] = useState(null);

  const [title, settitle] = useState("");
  const [tags, setTags] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    moduleIdWarning: false,
  });

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign columns
      if (jsonFilterData.visibleColoumns) {
        setVisibleColoumns(jsonFilterData.visibleColoumns);
      }

      //assign column order
      if (jsonFilterData.columnOrder && jsonFilterData.columnOrder.length > 0) {
        setColumnOrder(jsonFilterData.columnOrder);
      }

      //check date range type is not empty
      if (jsonFilterData.startDate) {
        setStartDate(jsonFilterData.startDate);
      }

      if (jsonFilterData.endDate) {
        setEndDate(jsonFilterData.endDate);
      }

      if (jsonFilterData.selectedModuleType) {
        setSelectedModuleType(jsonFilterData.selectedModuleType);
      } else {
        setSelectedModuleType(null);
      }

      if (jsonFilterData.noteLeadValue) {
        setNoteLeadId(jsonFilterData.noteLeadId);
        setNoteLeadValue(jsonFilterData.noteLeadValue);
      }

      if (jsonFilterData.noteContactValue) {
        setNoteContactId(jsonFilterData.noteContactId);
        setNoteContactValue(jsonFilterData.noteContactValue);
      }

      if (jsonFilterData.noteOpportunityValue) {
        setNoteOpportunityId(jsonFilterData.noteOpportunityId);
        setNoteOpportunityValue(jsonFilterData.noteOpportunityValue);
      }

      if (jsonFilterData.noteOwnerValue) {
        setNoteOwnerId(jsonFilterData.noteOwnerId);
        setNoteOwnerValue(jsonFilterData.noteOwnerValue);
      }

      if (jsonFilterData.title) {
        settitle(jsonFilterData.title);
      }

      if (jsonFilterData)
        if (jsonFilterData.tags) {
          //assign tags
          // Filtering the skills options based on values present in the json array
          const filteredTags = tagList.filter((item) =>
            jsonFilterData.tags.includes(item.value)
          );

          //run loop
          filteredTags.forEach((item) => {
            tagSelectionHandler(item);
          });
        }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //function for change module type
  const moduleTypeSelectionHandler = (val) => {
    if (val) {
      setSelectedModuleType(val);
    } else {
      setSelectedModuleType(null);

      setleadInput("");
      setcontactInput("");
      setleadOptions([]);
      setcontactOptions([]);
      setNoteLeadId(null);
      setNoteLeadValue(null);
      setNoteContactId(null);
      setNoteContactValue(null);
    }
  };

  //function for change lead input
  const leadInputChangeHandler = (val) => {
    setleadInput(val);
  };

  //function for change opportuntiy input
  const opportunityInputHandler = (val) => {
    setOpportunityInput(val);
  };

  //function for change contact input
  const contactInputChangeHandler = (val) => {
    setcontactInput(val);
  };

  //function for get lead options
  const getLeadOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_LEAD_OPTIONS +
        `?token=${token}&leadstringinput=${leadInput}`;

      console.log("url of lead------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in lead------>", response);

      if (response.status) {
        setleadOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get contact options
  const getContactOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CONTACTS_OPTIONS +
        `?token=${token}&userstring=${contactInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcontactOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get opportunity
  const getOpportunityOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_LIST_OPTIONS +
        `?token=${token}&invoicetitle=${opportunityInput}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setOpportunityOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* function for get owner list for notes
  const getUserListForNoteOwner = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${noteOwnerInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setnoteOwnerOptions([...response.data, userObj]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change lead
  const leadSelectionHandler = (val) => {
    if (val) {
      setNoteLeadValue(val);
      setNoteLeadId(val.value);
    } else {
      setNoteLeadValue(null);
      setNoteLeadId(null);
      setleadInput("");
      setleadOptions([]);
    }
  };

  //function for change contact
  const contactSelectionHandler = (val) => {
    if (val) {
      setNoteContactValue(val);
      setNoteContactId(val._id);
    } else {
      setNoteContactValue(null);
      setNoteContactId(null);
      setcontactInput("");
      setcontactOptions([]);
    }
  };

  //function for select opportunity
  const opportunitySelectionHandler = (val) => {
    if (val) {
      setNoteOpportunityValue(val);
      setNoteOpportunityId(val.value);
    } else {
      setNoteOpportunityValue(null);
      setNoteOpportunityId(null);
      setOpportunityInput("");
      setOpportunityOptions([]);
    }
  };

  //function for select note owner
  const noteOwnerSelectionHandler = (val) => {
    if (val) {
      setNoteOwnerValue(val);
      setNoteOwnerId(val.value);
    } else {
      setNoteOwnerValue(null);
      setNoteOwnerId(null);
      setnoteOwnerInput("");
      setnoteOwnerOptions([]);
    }
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = tags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (selectedModuleType) {
      if (
        noteLeadValue === null &&
        noteContactValue === null &&
        noteOpportunityValue === null
      ) {
        setValidation((prevState) => ({
          ...prevState,
          moduleIdWarning: true,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");

      if (startDate !== "" && endDate !== "") {
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
      } else {
        setFilterStartDate("");
        setFilterEndDate("");
      }

      if (noteLeadId) {
        setFilterLead(noteLeadId);
      } else {
        setFilterLead(null);
      }

      if (noteContactId) {
        setFilterContact(noteContactId);
      } else {
        setFilterContact(null);
      }

      if (noteOpportunityId) {
        setFilterOpportunity(noteOpportunityId);
      } else {
        setFilterOpportunity(null);
      }

      if (noteOwnerId) {
        setFilterOwner(noteOwnerId);
      } else {
        setFilterOwner(null);
      }

      if (title !== "") {
        setFilterTitle(title);
      } else {
        setFilterTitle("");
      }

      if (tags.length > 0) {
        setFilterTags(tags);
      } else {
        setFilterTags([]);
      }

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#noteListFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            visibleColoumns: visibleColoumns ?? {},
            columnOrder: columnOrder ?? [],

            startDate,
            endDate,
            selectedModuleType,
            noteLeadId,
            noteLeadValue,
            noteContactId,
            noteContactValue,
            noteOpportunityId,
            noteOpportunityValue,
            noteOwnerValue,
            noteOwnerId,
            title,
            tags,
          }),
          moduleslug: moduleName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(false);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            // console.log(jsonFilterData);

            //assign visible columns
            if (jsonFilterData.visibleColoumns) {
              setVisibleColoumns(jsonFilterData.visibleColoumns);
            }

            //assign column order
            if (
              jsonFilterData.columnOrder &&
              jsonFilterData.columnOrder.length > 0
            ) {
              setColumnOrder(jsonFilterData.columnOrder);
            }

            //check date range type is not empty
            if (jsonFilterData.startDate) {
              setFilterStartDate(jsonFilterData.startDate);
            } else {
              setFilterStartDate("");
            }

            if (jsonFilterData.endDate) {
              setFilterEndDate(jsonFilterData.endDate);
            } else {
              setFilterEndDate("");
            }

            if (jsonFilterData.noteLeadId) {
              setFilterLead(jsonFilterData.noteLeadId);
            } else {
              setFilterLead(null);
            }

            if (jsonFilterData.noteContactId) {
              setFilterContact(jsonFilterData.noteContactId);
            } else {
              setFilterContact(null);
            }

            if (jsonFilterData.noteOpportunityId) {
              setFilterOpportunity(jsonFilterData.noteOpportunityId);
            } else {
              setFilterOpportunity(null);
            }

            if (jsonFilterData.noteOwnerId) {
              setFilterOwner(jsonFilterData.noteOwnerId);
            } else {
              setFilterOwner(null);
            }

            if (jsonFilterData.title && jsonFilterData.title !== "") {
              setFilterTitle(jsonFilterData.title);
            } else {
              setFilterTitle("");
            }

            if (jsonFilterData.tags.length > 0) {
              setFilterTags(jsonFilterData.tags);
            } else {
              setFilterTags(filterLabels);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setValidation({ dateWarning: false, moduleIdWarning: false });

    setSelectedModuleType(null);

    setleadInput("");
    setcontactInput("");
    setOpportunityInput("");
    setnoteOwnerInput("");

    setleadOptions([]);
    setcontactOptions([]);
    setOpportunityOptions([]);
    setnoteOwnerOptions([userObj]);

    setStartDate("");
    setEndDate("");
    setNoteLeadValue(null);
    setNoteLeadId(null);
    setNoteContactValue(null);
    setNoteContactId(null);
    setNoteOpportunityValue(null);
    setNoteOpportunityId(null);
    setNoteOwnerId(null);
    setNoteOwnerValue(null);

    settitle("");
    setTags([]);

    seterrorMessage("");

    removeActiveClass();
    resetSelectTags();

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    getAllTags();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  useEffect(() => {
    if (leadInput.length > 0) {
      getLeadOptions();
    }
  }, [leadInput]);

  useEffect(() => {
    if (contactInput.length > 0) {
      getContactOptions();
    }
  }, [contactInput]);

  useEffect(() => {
    if (opportunityInput.length > 0) {
      getOpportunityOptions();
    }
  }, [opportunityInput]);

  useEffect(() => {
    if (noteOwnerInput !== "") {
      getUserListForNoteOwner();
    }
  }, [noteOwnerInput]);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="noteListFilter"
      aria-labelledby="noteListFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        {/* <!-- ========== Start popup close button Section ========== --> */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        {/* <!-- ========== End popup close button Section ========== --> */}
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* <!-- ========== Start date range Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>

            {/* <Select
              placeholder={t("Filter date by")}
              isClearable
              options={FilterDateOptions}
              value={subscribeMailValue}
              onChange={(val) => {
                subscribeSelectionHandler(val);
              }}
            /> */}

            <div className="d-flex gap-2 ">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start module type Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Module")}</h3>{" "}
            </label>
            <Select
              isClearable
              placeholder="Select Module"
              options={NoteModuleList}
              value={selectedModuleType}
              onChange={(val) => {
                moduleTypeSelectionHandler(val);
                setValidation((prevState) => ({
                  ...prevState,
                  typeWarning: false,
                }));
              }}
            />
            {validation.typeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please select module!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End module type Section ========== --> */}

          {/* <!-- ========== Start opportunity lead/contact Section ========== --> */}
          {selectedModuleType ? (
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Select Component")}</h3>{" "}
              </label>
              {selectedModuleType?.value === "lead" ? (
                <Select
                  isClearable
                  placeholder="Type Lead name or email"
                  options={leadOptions}
                  value={noteLeadValue}
                  onChange={(val) => {
                    leadSelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                  onInputChange={(val) => {
                    leadInputChangeHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                />
              ) : selectedModuleType?.value === "opportunity" ? (
                <Select
                  isClearable
                  placeholder="Type opportuntiy title"
                  options={opportunityOptions}
                  value={noteOpportunityValue}
                  onChange={(val) => {
                    opportunitySelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                  onInputChange={(val) => {
                    opportunityInputHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                />
              ) : (
                <Select
                  isClearable
                  placeholder="Type contact name"
                  options={contactOptions}
                  value={noteContactValue}
                  onChange={(val) => {
                    contactSelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                  onInputChange={(val) => {
                    contactInputChangeHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      moduleIdWarning: false,
                    }));
                  }}
                />
              )}

              {validation.moduleIdWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please select component with required fields!</span>
                  </p>
                </div>
              )}
            </div>
          ) : null}
          {/* <!-- ========== End opportunity lead/contact Section ========== --> */}

          {/* <!-- ========== Start note owner Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Moderator")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Search by name or email")}
                options={noteOwnerOptions}
                value={noteOwnerValue}
                onChange={(val) => {
                  noteOwnerSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  setnoteOwnerInput(val);
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End note owner Section ========== --> */}

          {/* <!-- ========== Start title Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              id="title"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter Title")}
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  titleWarning: false,
                }));
              }}
            />
            {validation.titleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter a title!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End title Section ========== --> */}

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start label/tag accordion Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapselabels"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapselabels"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapselabels"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />

                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tag)}
                            id={"tagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End label/tag accordion Section ========== --> */}
          </div>

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default NoteListFilterPopup;
