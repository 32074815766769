/* eslint-disable */
import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectLogFilterPopup = ({
  tagList,
  taskList,
  sectionOptions,
  memberList,
  setFilterTitle,
  setFilterDateRange,
  setFilterTags,
  setfilterTaskIds,
  setfilterSectionIds,
  setfilterUserIds,
}) => {
  const { t } = useTranslation(); //for translation
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [title, settitle] = useState("");

  const [taskValue, settaskValue] = useState(null);
  const [taskIds, settaskIds] = useState([]);

  const [sectionValue, setsectionValue] = useState(null);
  const [sectionIds, setsectionIds] = useState([]);

  const [userValue, setuserValue] = useState(null);
  const [userIds, setuserIds] = useState([]);

  const [tagValues, settagValues] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //function for select event tags
  const logTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const taskSelectionHandler = (val) => {
    settaskValue(val);
    settaskIds(val.map((item) => item.value));
  };

  const sectionSelectionHandler = (val) => {
    setsectionValue(val);
    setsectionIds(val.map((item) => item.value));
  };

  const userSelectionHandler = (val) => {
    setuserValue(val);
    setuserIds(val.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    setFilterTitle(title);
    setFilterTags(tagValues);
    setfilterTaskIds(taskIds);
    setfilterSectionIds(sectionIds);
    setfilterUserIds(userIds);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasLogFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    settitle("");
    setfromDate("");
    settoDate("");
    settagValues([]);
    setuserValue(null);
    setuserIds([]);
    settaskValue(null);
    settaskIds([]);
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasLogFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* dates */}
          <div className="form-group mb-4">
            <label htmlFor="Dates" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* -------- title ------------- */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>

            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Search By title")}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>

          {/* task */}
          <div className="form-group mb-4">
            <label htmlFor="Task" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Task")}</h3>{" "}
            </label>
            <Select
              options={taskList}
              isMulti
              value={taskValue}
              onChange={(val) => taskSelectionHandler(val)}
            />
          </div>

          {/* section */}
          <div className="form-group mb-4">
            <label htmlFor="Section" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Section")}</h3>{" "}
            </label>
            <Select
              options={sectionOptions}
              isMulti
              value={sectionValue}
              onChange={(val) => sectionSelectionHandler(val)}
            />
          </div>

          {/* owner */}
          <div className="form-group mb-4">
            <label htmlFor="Section" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Task user")}</h3>{" "}
            </label>
            <Select
              options={memberList}
              isMulti
              value={userValue}
              onChange={(val) => userSelectionHandler(val)}
            />
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* --------- Labels ------------- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => logTagSelectionHandler(tagData)}
                            id={"projectlogtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProjectLogFilterPopup;
