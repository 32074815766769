/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const ProjectListFilterPopup = ({
  tagList,
  setFilterTitle,
  setFilterDateRange,
  setFilterTags,
  setfilterMod,
  setfilterMember,
}) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const [modInput, setModInput] = useState("");
  const [modOptions, setModOptions] = useState([]);

  const [modValue, setModValue] = useState(null);
  const [modId, setModId] = useState(null);

  const [memberInput, setmemberInput] = useState("");
  const [memberOptions, setmemberOptions] = useState([]);
  const [memberValue, setmemberValue] = useState(null);
  const [memberId, setmemberId] = useState(null);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [title, settitle] = useState("");
  const [tagValues, settagValues] = useState([]);

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //function for get user details
  const getModrList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${modInput}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setModOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get input
  const modInputHandler = (val) => {
    setModInput(val);
  };

  //function Selection
  const modSelectionHandler = (val) => {
    setModValue(val);
    setModId(val._id);
  };

  //function for get user details
  const getMemberList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${memberInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setmemberOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get input
  const memberInputHandler = (val) => {
    setmemberInput(val);
  };

  //function Selection
  const memberSelectionHandler = (val) => {
    setmemberValue(val);
    setmemberId(val._id);
  };

  //function for select event tags
  const projectTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "projecttagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "projecttagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    setFilterTitle(title);
    setFilterTags(tagValues);
    setfilterMod(modId);
    setfilterMember(memberId);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasProjectFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    settitle("");
    setfromDate("");
    settoDate("");
    settagValues([]);
    setModValue(null);
    setModId(null);
    setmemberValue(null);
    setmemberId(null);
  };

  useEffect(() => {
    if (modInput.length > 3) {
      getModrList();
    }
  }, [modInput]);

  useEffect(() => {
    if (memberInput.length > 3) {
      getMemberList();
    }
  }, [memberInput]);

  const modOptionsToShow = modOptions.length > 0 ? modOptions : [];
  const memberOptionsToShow = memberOptions.length > 0 ? memberOptions : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasProjectFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      {/* -------- select filter ---------- */}
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          {/* dates */}
          <div className="form-group mb-4">
            <label htmlFor="Dates" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* -------- title ------------- */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>

            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Search By title")}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>

          {/* moderator */}
          <div className="form-group mb-4">
            <label
              htmlFor="moderator"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Moderator")}</h3>{" "}
            </label>

            <Select
              placeholder={t("Type user name or email")}
              options={modOptionsToShow}
              value={modValue}
              onChange={(val) => {
                modSelectionHandler(val);
              }}
              onInputChange={(val) => {
                modInputHandler(val);
              }}
            />
          </div>

          {/* Member */}
          <div className="form-group mb-4">
            <label htmlFor="Member" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Member")}</h3>{" "}
            </label>

            <Select
              placeholder={t("Type user name or email")}
              options={memberOptionsToShow}
              value={memberValue}
              onChange={(val) => {
                memberSelectionHandler(val);
              }}
              onInputChange={(val) => {
                memberInputHandler(val);
              }}
            />
          </div>

          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            {/* --------- Labels ------------- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => projectTagSelectionHandler(tagData)}
                            id={"projecttagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* --------- save filter / apply filter ------------ */}
          <div className="action d-flex gap-2 mt-4">
            {/* <Link
              to="#"
              className="btn btn-outline-primary flex-fill"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save Filter
            </Link> */}
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectListFilterPopup;
