/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const CRMLeadListHeader = ({
  userInfo,
  reloadLeadList = () => {},
  downloadAsExcel = () => {},
  enetredNumberForSelect = "",
  setEnetredNumberForSelect = () => {},
  setSelectedNumberValidationMessage = () => {},
  recordSelectionHandler = () => {},
  bulkUpdateHandler = () => {},
  selectedNumberValidationMessage = "",
  leadsDataList = [],
  followingLeadsCount = 0,
  opportunitiesTotalCount = 0,
  totalNotesCount = 0,
  totalConversationCount = 0,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("CRM"), link: "/admin/crm/lead/list" },
    { title: t("Leads") },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-2 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} top={false} />
        </div>
        <div className="col-md-10 col-lg-10 d-flex gap-1 gap-sm-2 justify-content-end">
          <ul className="d-flex align-items-center gap-2">
            <li role="presentation">
              Leads :{" "}
              <span className="text-primary">{leadsDataList.length}</span>
            </li>

            <li role="presentation">
              Opportunities :{" "}
              <span className="text-primary">{opportunitiesTotalCount}</span>
            </li>

            <li role="presentation">
              Following :{" "}
              <span className="text-primary">{followingLeadsCount}</span>
            </li>

            <li role="presentation">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center dropdown-toggle fw-bold"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="material-symbols-outlined icon-fill fw-bold">
                  more_vert
                </span>
              </Link>

              <ul className="dropdown-menu dropdown-menu-end p-3 bg-white border-0 rounded-10 shadow-sm overflow-hidden">
                <li className="mb-2" role="presentation">
                  Notes :{" "}
                  <span className="text-primary">{totalNotesCount}</span>
                </li>

                <li className="" role="presentation">
                  Conversations :{" "}
                  <span className="text-primary">{totalConversationCount}</span>
                </li>
              </ul>
            </li>

            {/* <li className="me-2" role="presentation">
              Notes : <span className="text-primary">{totalNotesCount}</span>
            </li>

            <li className="" role="presentation">
              Conversations :{" "}
              <span className="text-primary">{totalConversationCount}</span>
            </li> */}
          </ul>
          <div className="d-flex gap-2 align-items-center">
            <div className="checkbox_select_input_box">
              <input
                className="form-control shadow-none"
                placeholder={t("Enter Number to select")}
                type="number"
                min={0}
                value={enetredNumberForSelect}
                onChange={(e) => {
                  setEnetredNumberForSelect(e.target.value);
                  setSelectedNumberValidationMessage("");
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={recordSelectionHandler}
            >
              {t("Select")}
            </button>

            {selectedNumberValidationMessage === "" ? null : (
              <div className="error-message ms-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined icon-fill">
                    warning
                  </span>
                  <span>{selectedNumberValidationMessage}</span>
                </p>
              </div>
            )}
          </div>

          {/* <!-- ========== Start refresh Section ========== --> */}
          <Link
            onClick={reloadLeadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* <!-- ========== End refresh Section ========== --> */}

          {/* <!-- ========== Start view redirect Section ========== --> */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("List View")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/list`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("List View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/kanban`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("Kanban View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/kanbankit`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("DNDKIT Kanban View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/dashboard`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    grid_view
                  </span>
                  <span className="d-block">{t("Dashboard View")}</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- ========== End view redirect Section ========== --> */}

          {/* <!-- ========== Start Action Dropdown Section ========== --> */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addFollowers"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    person
                  </span>
                  <span className="d-block">{t("Add Followers")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addToList"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("Add To List")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAlertModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    alarm
                  </span>
                  <span className="d-block">{t("Create alert")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNewMail"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    email
                  </span>
                  <span className="d-block">{t("Send Mail")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={bulkUpdateHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    progress_activity
                  </span>
                  <span className="d-block">{t("Update Rank")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  onClick={downloadAsExcel}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    download
                  </span>
                  <span className="d-block">{t("Download as Excel")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#csvUploadModal"
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    upload
                  </span>
                  <span className="d-block">{t('CSV Upload')}</span>
                </Link>
              </li>
              {userInfo.role.slug === "ADMIN" ||
              userInfo.role.slug === "SUPER_ADMIN" ? (
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center gap-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#veriFyEmail"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      email
                    </span>
                    <span className="d-block">{t("Verify Emails")}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
          {/* <!-- ========== End Action Dropdown Section ========== --> */}

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCRMLeadListFilter"
            aria-controls="offcanvasCRMLeadListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="/admin/crm/lead/save"
            role="button"
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CRMLeadListHeader;
